import { Injectable } from '@angular/core';
//import mockDataJson from 'src/app/data/data.json';
import {CacheService} from 'ionic-cache';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NativeStorage} from '@ionic-native/native-storage/ngx';
import {TranslateService} from '@ngx-translate/core';
import { Router } from '@angular/router';
@Injectable({ providedIn: 'root' })

export class DataService {
  //mockData = mockDataJson;
  selectedLang:string;
  selectedHotel:string;
  url='https://app.conceptmedya.com/adalya/data/';
  public static mode?: string; //md or ios

  historyData:[]
  data: unknown;
  myData:any;
  hotels:any;
  showStories:number;
  modules:any;
  appLogo:string;
  hotelLogo:string;
  hotelTel:string;
  hotelAddress:string;
  hotelEmail:string

  public headers = new HttpHeaders({'Content-Type': 'multipart/form-data'});
  
  languages = [];
  

  constructor(private router: Router,private http: HttpClient,private cache: CacheService,private nativeStorage: NativeStorage,translate: TranslateService) {
    

     this.getData().subscribe((data: any) => {
      this.appLogo = data.settings[0].logo;
      this.hotels = data.settings[0].hotels;
      this.showStories = data.settings[0].showStories;
      this.languages = data.settings[0].languages;
      this.modules = data.modules;
      console.log(this.hotels)
    }, err => {});


    
    if (localStorage.getItem("lang") === null) {
    this.router.navigate(['/welcome']);
    }
    else
    {
      this.selectedLang=localStorage.getItem('lang')
      translate.use(this.selectedLang);
      this.setLang(this.selectedLang, translate);
    }
  }

  getData() {
    
    this.selectedLang=localStorage.getItem('lang');
    this.selectedHotel=localStorage.getItem('hotel');
    let url = this.url+'datadb.php?hotel='+this.selectedHotel+'&lang='+this.selectedLang;
    let cacheKey = this.selectedHotel+'-'+this.selectedLang+'-index';
    let request = this.http.get(url);

    return this.cache.loadFromObservable(cacheKey, request);
  }

  setLang(lang, translate: TranslateService) {
    this.languages.forEach(l => {
      if (l.flag == lang) {
        l.isChecked = true;
        translate.use(lang);
        localStorage.setItem('lang',lang)
      } else {
        l.isChecked = false;
      }
    });
  }

  setHotel(hotel) {
    this.hotels.forEach(h => {
      if (h.slug == hotel) {
        h.isChecked = true;
        localStorage.setItem('hotel',hotel)
        this.hotelLogo=h.logo;
        this.hotelTel=h.tel;
        this.hotelAddress=h.address;
        this.hotelEmail=h.email
      } else {
        h.isChecked = false;
      }
    });
  }

  getStories() {
    
    this.data = this.getData().subscribe((data: any) => {
      return data.stories;
    }, err => {});
  }

  getSeenFirtsStories() {

    this.data = this.getData().subscribe((data: any) => {
      return data.stories.sort((story1: any, story2: any) =>
      story1.seen > story2.seen ? 1 : story1.seen == story2.seen ? 0 : -1
      );
    }, err => {});


  }
  getNotifications() {
    this.selectedLang=localStorage.getItem('lang');
    this.selectedHotel=localStorage.getItem('hotel');
    let url = this.url+'datadb.php?hotel='+this.selectedHotel+'&lang='+this.selectedLang;
    let cacheKey = this.selectedHotel+'-'+this.selectedLang+'-notifications';
    let request = this.http.get(url);

    return this.cache.loadFromObservable(cacheKey, request);
  }
  
}
