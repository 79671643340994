import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DataService} from './services/data.service';
import {Platform} from '@ionic/angular';
import {CacheService} from 'ionic-cache';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  GlobalFields = DataService;
  constructor(private platform: Platform,private dataService: DataService,private translate: TranslateService,cache: CacheService) {
    translate.setDefaultLang('tr');
    cache.setDefaultTTL(60 * 60); //set default cache TTL for 1 hour
    this.initializeApp();

  }

  
  initializeApp() {
    this.platform.ready().then(() => {
      console.log(this.platform.platforms());
     

      //this.createBanner();
    });
  }
}
